import axiosXavier from '@/services/axios';

export default {
  getStudentAreaPage: () => {
    return axiosXavier.get('themes/page_config').then(({ data }) => data);
  },
  updateStudentAreaPage: formData => {
    return axiosXavier.put('themes/page_config', {
      page_config: formData,
    });
  },
};
