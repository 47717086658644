<template>
  <div class="loading">
    <hs-loading-icon cherry />
  </div>
</template>

<script>
export default {
  name: 'hsLoading',
};
</script>

<style lang="scss" scoped>
.loading {
  max-width: 100%;
  width: 100%;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
