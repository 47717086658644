










































import { Component, Prop, Vue } from 'vue-property-decorator';

const positionValidator = position => ['right', 'left'].includes(position);

@Component({})
export default class MSwitch extends Vue {
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: String, default: '' }) labelTitle: string;
  @Prop({ type: String, default: '' }) labelDescription: string;
  @Prop({ type: Boolean, default: false }) disabled: boolean;
  @Prop({ type: String, default: 'right', validator: positionValidator }) labelPosition: string;
  @Prop({ type: String, default: '' }) badgeText: string;

  get hasLabelData() {
    return Boolean(this.labelTitle || this.labelDescription);
  }

  get inputTitle() {
    return `Clique para ativar ou desativar: ${this.labelTitle}`;
  }

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }

  handleChange(e) {
    this.$emit('change', e.target.checked);
  }
}
