<template>
  <div>
    <PageHeader
      title="Área de membros"
      subTitle="Personalize e promova sua área de membros"
      :backText="$t('my-account.views.dashboard.title')"
      backRoute="MyAccountDashboard"
    >
    </PageHeader>
    <section class="content tlw-px-4 md:tlw-px-8 tlw-py-6"><Setup /></section>
  </div>
</template>

<script>
import PageHeader from '@/components/_structures/PageHeader.vue';
import Setup from './Setup';

export default {
  components: {
    PageHeader,
    Setup,
  },
};
</script>

<style></style>
