<template>
  <b-form @submit.prevent="onSubmit">
    <slot name="title"></slot>
    <slot></slot>
  </b-form>
</template>

<script>
export default {
  name: 'hsForm',
  methods: {
    onSubmit() {
      this.$emit('submit');
    },
  },
};
</script>
