<template>
  <div id="studentAreaPage">
    <Loading v-if="isLoading" />

    <template v-else>
      <div class="tlw-p-4 md:tlw-p-8 lg:tlw-p-8 tlw-bg-white tlw-rounded-md tlw-mb-2">
        <h4 class="mb-2 tlw-font-bold">{{ $t('myspark.student-area.conf-start') }}</h4>
        <p class="mb-4">
          {{ $t('myspark.student-area.conf-info') }}
        </p>

        <div class="tlw-relative">
          <div v-if="isUpdating" class="swtich_loading">
            <b-spinner small />
          </div>

          <MSwitch
            v-model="form.enabled"
            class="tlw-mb-4"
            :label-title="$t('myspark.student-area.habilitar')"
            :label-description="$t('myspark.student-area.habilitar-info')"
            :disabled="isUpdating"
            @change="togglePublicationPage()"
          />
        </div>

        <MButton
          icon="eye"
          variant="link"
          label="Visualizar template da página inicial de membros"
          class="tlw-px-0 tlw-py-0"
          @click="$bvModal.show('bannerPage')"
        />
      </div>

      <div class="tlw-p-4 md:tlw-p-8 lg:tlw-p-8 tlw-bg-white tlw-rounded-md tlw-mb-2">
        <div class="tlw-flex tlw-items-baseline tlw-justify-between flex-wrap-reverse">
          <h4 class="mb-2 tlw-font-bold">{{ $t('myspark.student-area.recommended-products-section.title') }}</h4>
          <div class="btn-help tlw-text-right" @click="$bvModal.show('bannerRecommended')">
            <hs-icon icon="eye" class="pr-1" :size="14" />
            {{ $t('myspark.student-area.why') }}
          </div>
        </div>
        <p class="mb-4">
          {{ $t('myspark.student-area.recommended-products-section.subtitle') }}
        </p>
        <MButton
          data-testid="recommended-products-modal"
          class="mt-4 mt-lg-0"
          type="button"
          icon="book-alt"
          :label="$t('myspark.student-area.recommended-products-section.btn-label')"
          variant="primary-outline"
          @click="openRecommendedModal"
        />
      </div>

      <hs-form @submit="updateImagesStudentArea">
        <div class="tlw-p-4 md:tlw-p-8 lg:tlw-p-8 tlw-bg-white tlw-rounded-md tlw-mb-2">
          <div class="tlw-flex tlw-items-baseline tlw-justify-between flex-wrap-reverse">
            <h4 class="mb-4 tlw-font-bold">{{ $t('myspark.student-area.banner-title') }}</h4>
            <div class="btn-help tlw-text-right" @click="$bvModal.show('bannerModal')">
              <hs-icon icon="eye" class="pr-1" :size="14" />
              {{ $t('myspark.student-area.why') }}
            </div>
          </div>
          <div>
            <div v-for="(banner, index) in $v.form.top_banner.$each.$iter" :key="index">
              <div class="tlw-grid tlw-grid-cols-1 md:tlw-grid-cols-2 lg:tlw-grid-cols-2">
                <div>
                  <div class="tlw-flex align-items-baseline mb-2 mx-md-4 mx-lg-4 mx-0 tlw-font-bold label-desk">
                    {{ $t('myspark.student-area.fomart-desk') }}
                    <div :class="`${parseInt(index) === 0 ? 'tlw-hidden' : ''}`">
                      <hs-icon
                        v-b-tooltip.hover.right="$t('myspark.student-area.tooltip-label')"
                        variant="regular"
                        icon="info-circle"
                        class="u-info-circle"
                      />
                    </div>
                  </div>
                  <p :class="`mb-4 mx-md-4 mx-lg-4 mx-0 tlw-w-4/5 ${index != 0 ? 'tlw-hidden' : ''}`">
                    {{ $t('myspark.student-area.recommend-for') }} <span class="tlw-font-bold">1016px</span>
                    {{ $t('myspark.student-area.largura') }} <span class="tlw-font-bold">410px</span> de altura para os
                    banners desktop.
                  </p>
                  <div class="tlw-flex">
                    <p class="number-img">{{ parseInt(index) + 1 }}</p>
                    <ImageUploader
                      v-model="banner.$model.images.lg"
                      :image="banner.$model.images.lg"
                      :schoolId="schoolId"
                      height="133"
                      width="329"
                    />
                  </div>
                </div>
                <div>
                  <div class="tlw-flex align-items-baseline mb-2 tlw-font-bold label-desk">
                    {{ $t('myspark.student-area.format-mobile') }}
                    <div :class="`${parseInt(index) === 0 ? 'tlw-hidden' : ''}`">
                      <hs-icon
                        variant="light"
                        icon="info-circle"
                        class="u-info-circle"
                        v-b-tooltip.hover.right="$t('myspark.student-area.tooltip-label-2')"
                      />
                    </div>
                  </div>
                  <p :class="`mb-4 tlw-w-4/5 ${index != 0 ? 'tlw-hidden' : ''}`">
                    {{ $t('myspark.student-area.recommend-for') }} <span class="tlw-font-bold">330px</span> de largura
                    para e <span class="tlw-font-bold">300px</span> de altura para os banners mobile.
                  </p>
                  <ImageUploader
                    v-model="banner.$model.images.sm"
                    :image="banner.$model.images.sm"
                    :schoolId="schoolId"
                    height="133"
                    width="153"
                  />
                </div>
              </div>
              <div class="mt-2 mb-3 mx-md-4 mx-lg-4 mx-0 tlw-w-4/5">
                <p>{{ $t('myspark.student-area.banner-link') }}</p>
                <hs-input
                  v-model="banner.$model.link"
                  :disabled="!banner.$model.images.lg && !banner.$model.images.sm"
                  placeholder="https://exemplo.com.br"
                  @blur="$v.form.top_banner.$each[index].link.$touch()"
                  :state="!$v.form.top_banner.$each[index].link.$error ? null : false"
                >
                  <template slot="feedback" v-if="$v.form.top_banner.$each[index].link.$error">
                    <hs-form-invalid-feedback :state="false">
                      <hs-icon variant="light" icon="info-circle mr-1" :size="14" />
                      {{ $t('myspark.student-area.error-link-message') }}
                    </hs-form-invalid-feedback>
                  </template>
                </hs-input>
              </div>
              <div class="alert-info-silver mx-md-4 mx-lg-4 mx-0">
                <hs-icon variant="light" icon="info-circle" class="mr-2" size="16" />
                Caso não adicione uma imagem no formato, não será apresentada naquele dispositivo.
              </div>
              <hr :class="`m-4 ${parseInt(index) === 2 ? 'tlw-hidden' : ''}`" />
            </div>
          </div>
        </div>

        <div class="tlw-p-4 md:tlw-p-8 lg:tlw-p-8 tlw-bg-white tlw-rounded-md tlw-mb-2">
          <div class="tlw-flex tlw-items-baseline tlw-justify-between flex-wrap-reverse">
            <h4 class="mb-4 tlw-font-bold">{{ $t('myspark.student-area.card-title') }}</h4>
            <div class="btn-help tlw-text-right" @click="$bvModal.show('bannerMiddleModal')">
              <hs-icon icon="eye" class="pr-1" :size="14" />
              {{ $t('myspark.student-area.why') }}
            </div>
          </div>
          <div class="mb-4">
            <label for="">{{ $t('myspark.student-area.area-name') }}</label>
            <hs-input v-model="form.cards.title" placeholder="Adicione um nome"> </hs-input>
          </div>
          <p class="mb-2 mx-md-4 mx-lg-4 mx-0 tlw-font-bold label-desk">
            {{ $t('myspark.student-area.format-desk-mobile') }}
          </p>
          <p class="mb-4 mx-md-4 mx-lg-4 mx-0">
            {{ $t('myspark.student-area.recommend-for') }} <span class="tlw-font-bold">325px</span>
            {{ $t('myspark.student-area.largura') }} <span class="tlw-font-bold">345px</span> de altura para os banners
            desktop e mobile.
          </p>
          <div>
            <div
              v-for="(cards, index) in $v.form.cards.content.$each.$iter"
              :key="index"
              class="tlw-flex align-items-start flex-lg-nowrap flex-md-nowrap flex-wrap-reverse tlw-mb-5"
            >
              <div class="tlw-flex">
                <p class="number-img">{{ parseInt(index) + 1 }}</p>
                <ImageUploader
                  v-model="cards.$model.image"
                  :image="cards.$model.image"
                  :schoolId="schoolId"
                  height="213"
                  width="240"
                />
              </div>
              <div class="ml-0 mb-2 ml-sm-4 w-100">
                <p>{{ $t('myspark.student-area.banner-link') }}</p>
                <hs-input
                  v-model="cards.$model.link"
                  :disabled="!cards.$model.image"
                  placeholder="https://exemplo.com.br"
                  @blur="$v.form.cards.content.$each[index].link.$touch()"
                  :state="!$v.form.cards.content.$each[index].link.$error ? null : false"
                >
                  <template slot="feedback" v-if="$v.form.cards.content.$each[index].link.$error">
                    <hs-form-invalid-feedback :state="false">
                      <hs-icon variant="light" icon="info-circle mr-1" :size="14" />
                      {{ $t('myspark.student-area.error-link-message') }}
                    </hs-form-invalid-feedback>
                  </template>
                </hs-input>
              </div>
            </div>
          </div>
        </div>

        <div class="tlw-p-4 md:tlw-p-8 lg:tlw-p-8 tlw-bg-white tlw-rounded-md tlw-mb-2">
          <div class="tlw-flex tlw-items-baseline tlw-justify-between flex-wrap-reverse">
            <h4 class="mb-4 tlw-font-bold">{{ $t('myspark.student-area.banner-footer') }}</h4>
            <div class="btn-help tlw-text-right" @click="$bvModal.show('bannerFooterModal')">
              <hs-icon icon="eye" class="pr-1" :size="14" />
              {{ $t('myspark.student-area.why') }}
            </div>
          </div>
          <div class="mb-4">
            <label for="">{{ $t('myspark.student-area.area-name') }}</label>
            <hs-input v-model="form.bottom_banner.title" placeholder="Adicione um nome" />
          </div>
          <div class="tlw-grid tlw-grid-cols-1 md:tlw-grid-cols-2 lg:tlw-grid-cols-2">
            <div class="mr-2 mr-md-0 mr-lg-0">
              <p class="mb-2 mx-md-4 mx-lg-4 mx-0 tlw-font-bold tlw-w-4/5 label-desk">
                {{ $t('myspark.student-area.fomart-desk') }}
              </p>
              <p class="mb-4 mx-md-4 mx-lg-4 mx-0 tlw-w-4/5">
                {{ $t('myspark.student-area.recommend-for') }} <span class="tlw-font-bold">1016px</span>
                {{ $t('myspark.student-area.largura') }} <span class="tlw-font-bold">210px</span> de altura para os
                banners desktop.
              </p>
              <div class="tlw-flex align-items-start flex-lg-nowrap flex-md-nowrap flex-wrap-reverse">
                <div class="tlw-flex tlw-w-4/5">
                  <p class="number-img">1</p>
                  <ImageUploader
                    v-model="form.bottom_banner.images.lg"
                    :image="form.bottom_banner.images.lg"
                    :schoolId="schoolId"
                    height="119"
                    :uploadId="1"
                  />
                </div>
              </div>
            </div>
            <div>
              <p class="mb-2 tlw-font-bold label-desk">{{ $t('myspark.student-area.format-mobile') }}</p>
              <p class="mb-4 tlw-w-4/5">
                {{ $t('myspark.student-area.recommend-for') }} <span class="tlw-font-bold">328px</span>
                {{ $t('myspark.student-area.largura') }} <span class="tlw-font-bold">195px</span> de altura para os
                banners mobile.
              </p>
              <div class="tlw-flex">
                <ImageUploader
                  v-model="form.bottom_banner.images.sm"
                  :image="form.bottom_banner.images.sm"
                  :schoolId="schoolId"
                  height="160"
                  width="269"
                  :uploadId="1"
                />
              </div>
            </div>
          </div>
          <div class="ml-0 mb-2 ml-sm-2 w-100">
            <p>{{ $t('myspark.student-area.banner-link') }}</p>
            <hs-input
              v-model="form.bottom_banner.images.link"
              :disabled="!form.bottom_banner.images.lg && !form.bottom_banner.images.sm"
              placeholder="https://exemplo.com.br"
              @blur="$v.form.bottom_banner.images.link.$touch()"
              :state="!$v.form.bottom_banner.images.link.$error ? null : false"
            >
              <template slot="feedback" v-if="$v.form.bottom_banner.images.link.$error">
                <hs-form-invalid-feedback :state="false">
                  <hs-icon variant="light" icon="info-circle mr-1" :size="14" />
                  {{ $t('myspark.student-area.error-link-message') }}
                </hs-form-invalid-feedback>
              </template>
            </hs-input>
          </div>
        </div>

        <div class="tlw-flex tlw-justify-end mt-2 py-4">
          <MButton
            variant="primary"
            type="submit"
            class="pull-right"
            :disabled="$v.form.$invalid || isUpdating"
            :loading="isUpdating"
            :label="$t('general.save')"
          />
        </div>
      </hs-form>
    </template>

    <div v-for="modal in modalData" :key="modal.id">
      <ModalHelp :idModal="modal.id" :imgName="modal.imgName" :title="modal.title" />
    </div>

    <RecommendedProductsModal
      v-if="modalUpdateRecommended"
      modalId="recommended-products-modal"
      @closeModal="modalUpdateRecommended = false"
      @updateRecommended="updateRecommended($event)"
    />
  </div>
</template>

<script>
import debug from 'debug';
import { mapState } from 'vuex';
import { url } from 'vuelidate/lib/validators';
import _ from 'lodash';

import TrackingHelper from '@/shared/helpers/tracking';
import ToastHelper from '@/shared/helpers/toast';
import themesService from '@/services/themes';
import productService from '@/sparkmembers/services/product';

import hsForm from '@/components/Form.vue';
import MButton from '@/shared/components/MButton.vue';
import Loading from '@/components/Loading.vue';
import MSwitch from '@/shared/components/MSwitch.vue';

const logging = debug('hs:student_area');

export default {
  components: {
    MSwitch,
    ImageUploader: () => import('./components/ImageUploader'),
    ModalHelp: () => import('./components/ModalHelp'),
    RecommendedProductsModal: () => import('./components/RecommendedProductsModal'),
    hsForm,
    MButton,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      isLoadingProducts: 'done',
      form: {
        enabled: false,
        top_banner: this.banners(),
        cards: {
          title: '',
          content: this.cards(),
        },
        bottom_banner: {
          title: '',
          images: {
            lg: '',
            sm: '',
            link: '',
          },
        },
      },
      modalUpdateRecommended: false,
      modalData: [
        {
          id: 'bannerRecommended',
          imgName: 'banner_recommended',
          title: 'Seção de produtos',
        },
        {
          id: 'bannerModal',
          imgName: 'banner',
          title: this.$t('myspark.student-area.banner_title_main'),
        },
        {
          id: 'bannerMiddleModal',
          imgName: 'banner_middle',
          title: this.$t('myspark.student-area.banner_cards'),
        },
        {
          id: 'bannerFooterModal',
          imgName: 'banner_footer',
          title: this.$t('myspark.student-area.banner_cards_footer'),
        },
        {
          id: 'bannerPage',
          imgName: 'banner_page',
          title: this.$t('myspark.student-area.banner_page'),
        },
      ],
      products: [],
    };
  },
  validations: {
    form: {
      top_banner: {
        $each: {
          link: {
            url: url,
          },
        },
      },
      cards: {
        content: {
          $each: {
            link: {
              url: url,
            },
          },
        },
      },
      bottom_banner: {
        images: {
          link: {
            url: url,
          },
        },
      },
    },
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
  },
  async created() {
    await this.getPage();
  },
  methods: {
    async openRecommendedModal() {
      this.modalUpdateRecommended = true;
      this.$bvModal.show('recommended-products-modal');
    },
    async updateRecommended(payload) {
      try {
        this.isLoadingProducts = 'updating';

        await productService.updateRecommended(payload);

        ToastHelper.successMessage(this.$t('myspark.student-area.recommended-products-section.toast.success'));
      } catch (err) {
        ToastHelper.dangerMessage(this.$t('myspark.student-area.recommended-products-section.toast.error'));
      } finally {
        this.isLoadingProducts = 'done';
        this.modalUpdateRecommended = false;
      }
    },
    banners() {
      const banner_model = [];
      for (let index = 0; index < 3; index++) {
        banner_model.push({
          images: {
            sm: '',
            lg: '',
          },
          link: '',
          order: index,
        });
      }
      return banner_model;
    },
    cards() {
      const cards_model = [];
      for (let index = 0; index < 3; index++) {
        cards_model.push({
          image: '',
          link: '',
          order: index,
        });
      }
      return cards_model;
    },
    async updateImagesStudentArea() {
      try {
        this.isUpdating = true;
        await themesService.updateStudentAreaPage(this.form);
        this.trackEvents();
        ToastHelper.successMessage(this.$t('myspark.student-area.toggle-sucess'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('myspark.student-area.toggle-danger'));
      } finally {
        this.isUpdating = false;
      }
    },
    async togglePublicationPage() {
      try {
        this.isUpdating = true;
        await themesService.updateStudentAreaPage(this.form);

        if (this.form.enabled) {
          TrackingHelper.trackNewThemeActive();
        }

        let messageInfo = this.form.enabled
          ? this.$t('myspark.student-area.published')
          : this.$t('myspark.student-area.unpublished');
        ToastHelper.successMessage(
          this.$t('myspark.student-area.publish-toggle', {
            messageInfo: messageInfo,
          })
        );
      } catch (error) {
        this.$nextTick(() => {
          this.$set(this.form, 'enabled', !this.form.enabled);
        });

        ToastHelper.dangerMessage(
          this.$t('myspark.student-area.erro-log'),
          this.$t('myspark.student-area.erro-publish')
        );
      } finally {
        this.isUpdating = false;
      }
    },
    async getPage() {
      try {
        this.isLoading = true;
        const form = await themesService.getStudentAreaPage();

        if (_.isEmpty(form)) return;

        this.form = form;
      } catch (error) {
        logging(this.$t('myspark.student-area.erro-logging'), error);
      } finally {
        this.isLoading = false;
      }
    },
    trackEvents() {
      const topBanner = this.form.top_banner;
      const topBannerSm = topBanner.map(({ images }) => images).some(({ sm }) => sm);
      const topBannerLg = topBanner.map(({ images }) => images).some(({ lg }) => lg);
      const topBannerLink = topBanner.some(({ link }) => link);

      const cardBanner = this.form.cards.content;
      const CardBannerImage = cardBanner.some(({ image }) => image);
      const cardBannerLink = cardBanner.some(({ link }) => link);

      const bottomLg = this.form.bottom_banner.images.lg;
      const bottomSm = this.form.bottom_banner.images.sm;
      const bottomLink = this.form.bottom_banner.images.link;

      if (topBannerLg || topBannerSm || topBannerLink) {
        const format = this.getSizeImage(topBannerLg, topBannerSm);
        TrackingHelper.trackStudentAreBannerSend('top_banner', format, topBannerLink);
      }

      if (CardBannerImage || cardBannerLink) {
        TrackingHelper.trackStudentAreBannerSend('middle_banner', 'lg', cardBannerLink);
      }

      if (bottomSm || bottomLg || bottomLink) {
        const format = this.getSizeImage(bottomLg, bottomSm);
        const haveLink = bottomLink.length ? true : false;
        TrackingHelper.trackStudentAreBannerSend('bottom_banner', format, haveLink);
      }
    },
    getSizeImage(sm, lg) {
      const format = [];
      if (sm) format.push('sm');
      if (lg) format.push('lg');

      return format.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
#studentAreaPage {
  h4 {
    font-size: 20px;
  }

  .swtich_loading {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 33px;
    background: #fff;
    z-index: 1;
  }

  /deep/ .m-switch {
    max-width: max-content;
  }

  .label-desk {
    font-size: 16px;
  }

  .invalid-feedback {
    font-size: 12px;
  }

  .number-img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 8px 0px 0px;
    background: #e1e1e1;
    border: 1px solid #262626;
    box-sizing: border-box;
  }

  .btn-help {
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    color: $purple-dark;
    cursor: pointer;
  }

  .alert-info-silver {
    background: #e3e3e3;
    padding: 16px;
    color: #6f6f6f;
    font-weight: 400;
    line-height: 20.3px;
    font-size: 'Mulish';
    border-radius: 4px;
    display: flex;
    align-items: baseline;
  }

  .u-info-circle {
    color: #3575d4;
    font-size: 0.875rem;
    margin-left: 0.5rem;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
